/////////////////////////////////////////
// Declare project variables           //
/////////////////////////////////////////

// Base variables
$base-wrapper:              1215px;
$body-bgc:                  #fff;

// Brand colors
$bwhite:                    #fff;
$bblack:                    #2a2a2a;
$bblack-light:              #9c9c9c;
$bblack-dark:               #484a4a;

$bprimary-light:            #91a71c;
$bprimary:                  #647700;
$bprimary-dark:             #495700;

$bsecondary-light:          #7b198a;
$bsecondary:                #5b0f67;
$bsecondary-dark:           #4a0d54;

$bcolor1:                   #92a81c; // green
$bcolor2:                   #b93053; // pink
$bcolor3:                   #7c198b; // purple
$bcolor4:                   #265b98; // blue
$bcolor5:                   #894859; // culture
$bcolors:                   $bcolor1, $bcolor2, $bcolor3, $bcolor4, $bcolor5;

$gray:                      #dbdbdb;
$gray-bg:                   #f2f2f2;
$gray-dark:                 #9c9c9c;
$gray-darker:               #4b4b4b;
$gray-lightest:             #f6f6f6;

$bsuccess:                  #068e21;
$bwarning:                  #f65e1f;
$bdanger:                   #d41f3a;
$bhighlight:                $bprimary;

$tc-link:                   $bblack;
$tc-link-hover:             darken($tc-link, 5%);

// Font declarations

@font-face {
    font-family: 'LatoWeb';
    src: url('..fonts/LatoLatin-Regular.eot');
    src: url('..fonts/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),
         url('..fonts/LatoLatin-Regular.woff2') format('woff2'),
         url('..fonts/LatoLatin-Regular.woff') format('woff'),
         url('..fonts/LatoLatin-Regular.ttf') format('truetype'),
         url('..fonts/LatoLatin-Regular.svg#LatoWeb') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('..fonts/LatoLatin-Black.eot');
    src: url('..fonts/LatoLatin-Black.eot?#iefix') format('embedded-opentype'),
         url('..fonts/LatoLatin-Black.woff2') format('woff2'),
         url('..fonts/LatoLatin-Black.woff') format('woff'),
         url('..fonts/LatoLatin-Black.ttf') format('truetype'),
         url('..fonts/LatoLatin-Black.svg#LatoWeb') format('svg');
    font-weight: 800;
    font-style: normal;
}

$fw-hairline:           400;
$fw-light:              400;
$fw-regular:            400;
$fw-semibold:           800;
$fw-bold:               800;
$fw-extrabold:          800;
$fw-heavy:              800;

$fwh:                   $fw-hairline;
$fwl:                   $fw-light;
$fwr:                   $fw-regular;
$fws:                   $fw-semibold;
$fwb:                   $fw-bold;
$fwe:                   $fw-extrabold;
$fwy:                   $fw-heavy;

// Base Font
$tc-base:               $bblack;
$base-font-size:        16;
$base-font-sizepx:      16px;
$base-line-height:      1.5em;
$base-font-family:      'LatoWeb', Helvetica, Arial, sans-serif;
$base-font-weight:      $fw-regular;
$bfaf:                  $base-font-family;

$icon-font-family:      'FontAwesome';
$ifaf:                  $icon-font-family;

// Font sizes
@function em($target, $context: $base-font-size) {
    @return ($target / $context) * 1em;
}