.page-body {
    width: 100%;
    display: block;
    color: $bblack;
    font-size: em(16);
    font-weight: $fwr;
    font-family: $bfaf;
    line-height: $base-line-height;

    div, p {
        color: $bblack;
        font-size: em(16);
        font-weight: $fwr;
        line-height: $base-line-height;
        margin-bottom: 1em;
    }

    h2, h3, h4, h5, h6 {
        color: $bblack;
        font-weight: $fwb;
        line-height: $base-line-height;
        margin-top: 1.2em;
        margin-bottom: .8em;
    }

    h2 { font-size: em(24); }
    h3 { font-size: em(19); }
    h4 { font-size: em(18); }
    h5 { font-size: em(17); }
    h6 { font-size: em(16); }

    ul, ol {
        width: 96%;
        margin: .8em 0 1.2em 4%;
    }

    ul li, ol li {
        list-style-type: disc;
        font-weight: $fwr;
    }

    ol li { list-style-type: decimal; }
    ul li {
        list-style: none;
        padding: .35em 0;
        &:before {
            margin-left: -1em;
            margin-right: .5em;
            content: '\f105';
            color: $bsecondary;
            font-family: $ifaf;
        }
    }

    a {
        color: $bprimary-light;
        text-decoration: underline;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: .8em 0 1.2em 0;
    }

    table td,
    table th {
        font-size: 1em;
        padding: .5em;
        border-bottom: 1px solid #e1e1e1;
        vertical-align: middle;
    }

    .stressed-text {
        color: $bprimary;
    }

    figure.image {
        position: relative;
        max-width: 80%;
        display: block;
        margin: 1em auto;
        &.video-embed {
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;
            iframe {
                position: absolute; top: 0; left: 0;
                width: 100%;
                height: 100%;
            }
        }
        &.open-gallery {
            &:after {
                content: '\f00e';
                position: absolute; right: 20px; top: 20px;
                z-index: 400;
                font-family: $icon-font-family;
                font-size: 32px;
                color: $bwhite;
                text-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
            }
        }
        img {
            max-width: 100%;
            position: relative;
            z-index: 200;
            height: auto;
            display: block;
        }
        figcaption {
            margin: 0;
            padding: 7px;
            background: $gray-bg;
            font-size: .85em;
            text-align: center;
        }
    }
}