@import "./vendor/bower_components/compass-sass-mixins/lib/compass";
@import "./vendor/bower_components/font-awesome/scss/font-awesome";

@import "reset";
@import "variables";
@import "mixins";

@page {
    size: A4;
    margin: 0;
}

html, body {
    width: 100%;
}

body {
    background: $bwhite;
    font-family: $base-font-family;
    font-size: 14px;
    line-height: 1.5em;
    color: $bblack;
    overflow-x: hidden;
}

.site-col.pull-left {
    width: 100%;
}

header#header, footer#footer, .site-col.pull-right, .breadcrumb {
    display: none;
}

h1.page-title {
    @include wf;
    font-size: 36px;
    font-weight: $fwe;
    line-height: 1.3em;
    margin-bottom: .5em;
    span { color: $gray-dark; }
    i.fa { color: $gray-dark; margin-left: 10px; }
}

@import "npsb-modules/pagebody";